import React, { useEffect, useState } from 'react';
import SoveGodt01 from '../assets/img/photos/Sovegodt01.jpg';
import OvsteArkja from '../assets/img/photos/OvsteArkja.jpeg';
import YtseSalen from '../assets/img/photos/YtseSalen.jpeg';
import NesteArkja from '../assets/img/photos/NesteArkja.jpeg';
import SalMedAlkove from '../assets/img/photos/SalMedAlkove.jpeg';
import styles from './Romma.module.css';
import { GApageView } from '../index.js';
import { Text, EngText } from '../assets/TextResources';

const roomPictures = [OvsteArkja, NesteArkja, SalMedAlkove, YtseSalen];

const Romma = ({ language }) => {
  const [textResource, setResource] = useState(Text);

  useEffect(() => {
    GApageView('/Romma');
  }, []);
  useEffect(() => {
    language === 'ENG' ? setResource(EngText) : setResource(Text);
  }, [language]);

  return (
    <div className={styles.sleep_container}>
      <img
        src={SoveGodt01}
        alt='View of the fjord'
        className={styles.sleep_hero_image}
      />
      <div className={styles.sleep_paragraph}>
        <h1 className={styles.sleep_title_paragraph}>
          {textResource.SLEEP.SOVE_GODT.TITLE}
        </h1>
        <p>{textResource.SLEEP.SOVE_GODT.FIRST_PARAGRAPH}</p>
        <h1 className={styles.sleep_title_paragraph}>
          {textResource.SLEEP.SOVE_GODT.SEC_TITLE}
        </h1>
      </div>
      {Object.entries(textResource.SLEEP.ROOMS).map(([key, value], index) => {
        return (
          <div key={key} className={styles.room_container}>
            <div className={styles.room_paragraph}>
              <h2 className={styles.room_title}>{value.TITLE}</h2>
              <p className={styles.room_description}>{value.DESCRIPTION}</p>
            </div>
            {roomPictures[index] ? (
              <img
                className={styles.room_picture}
                src={roomPictures[index]}
                alt='View of the room'
              />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
export default Romma;
