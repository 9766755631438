import React, { useState, useEffect } from 'react';
import styles from './BackToTop.module.css';
import { Text, EngText } from '../../assets/TextResources';

import Box from '@mui/material/Box';
import NorthOutlinedIcon from '@mui/icons-material/NorthOutlined';

const BackToTop = ({ language }) => {
  const [textResource, setResource] = useState(Text);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    language === 'ENG' ? setResource(EngText) : setResource(Text);
  }, [language]);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <Box
      component='button'
      onClick={scrollToTop}
      className={styles.to_top_button}
      sx={{
        display: visible ? 'inline' : 'none',
      }}
    >
      <NorthOutlinedIcon
        fontSize='medium'
        sx={{
          display: 'block',
          margin: 'auto',
          padding: '0',
          fontSize: {
            xs: '1rem',
            sm: '1.7rem',
          },
        }}
      />
      <p className={styles.to_top_text}>
        {textResource.TO_TOP_BUTTON.BUTTON_TEXT}
      </p>
    </Box>
  );
};

export default BackToTop;
