import React, { useEffect, useState } from 'react';

import Carousel, { CarouselItem } from '../components/carousel/Carousel';
import styles from './Hagen.module.css';
import { Text, EngText } from '../assets/TextResources';
import {
  Hagen01,
  Hagen02,
  Hagen03,
  Hagen04,
  Hagen05,
  Hagen06,
  Hagen07,
  Hagen08,
  Hagen09,
  LogoGreenSimple,
} from '../assets/img/index.js';
import { GApageView } from '../index.js';

const Hagen = ({ language }) => {
  const [textResource, setResource] = useState(Text);
  const gardenImages = [
    Hagen02,
    Hagen03,
    Hagen04,
    Hagen05,
    Hagen06,
    Hagen07,
    Hagen08,
    Hagen09,
  ];
  useEffect(() => {
    GApageView('/Hagen');
  }, []);
  useEffect(() => {
    language === 'ENG' ? setResource(EngText) : setResource(Text);
  }, [language]);

  return (
    <div className={styles.garden_container}>
      <img
        src={Hagen01}
        alt='Overview of the garden'
        className={styles.garden_hero_image}
      />
      <div className={styles.garden_paragraph}>
        <h1 className={styles.garden_title_paragraph}>
          {textResource.GARDEN.TITLE}
        </h1>
        <p>{textResource.GARDEN.FIRST_PARAGRAPH}</p>
      </div>
      <Carousel language={language}>
        {gardenImages.map((src, index) => (
          <CarouselItem key={index}>
            <img
              src={src}
              alt='garden'
              className={styles.garden_image_carousel}
            />
          </CarouselItem>
        ))}
      </Carousel>
      <img
        className={styles.garden_logo_image}
        src={LogoGreenSimple}
        alt='LogoGreenLeafSimple'
      />
    </div>
  );
};
export default Hagen;
