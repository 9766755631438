import React from "react";
import styles from "./Layout.module.css";

const Layout = ({ header, footer, children, toTop }) => {
  return (
    <>
      {header}
      <main>
        <div className={styles.container}>{children}</div>
      </main>
      <div className={styles.to_top_container}>{toTop}</div>
      {footer}
    </>
  );
};

export default Layout;
