import React, { useEffect, useState } from 'react';
import styles from './Vertskapet.module.css';
import { Vertskapet01, Vertskapet02 } from '../assets/img/';
import { GApageView } from '../index.js';
import { Text, EngText } from '../assets/TextResources';

const Vertskapet = ({ language }) => {
  const [textResource, setResource] = useState(Text);

  useEffect(() => {
    GApageView('/Vertskapet');
  }, []);
  useEffect(() => {
    language === 'ENG' ? setResource(EngText) : setResource(Text);
  }, [language]);

  return (
    <div className={styles.host_container}>
      <img
        src={Vertskapet01}
        alt='Janne og Heidi-Kathrin'
        className={styles.host_hero_image}
      />
      <p className={styles.host_paragraph}>
        <span className={styles.host_ingress}>
          {textResource.ABOUT.FIRST_PARAGRAPH}
        </span>
        <span className={styles.host_details}>
          {textResource.ABOUT.SEC_PARAGRAPH}
        </span>
      </p>
      <img
        src={Vertskapet02}
        alt='Einvald og Gerd'
        className={styles.host_hero_image}
      />
    </div>
  );
};

export default Vertskapet;
