import React, { useEffect, useState } from 'react';
import Carousel, { CarouselItem } from '../components/carousel/Carousel';
import styles from './Maten.module.css';
import { Text, EngText } from '../assets/TextResources';
import {
  Maten01,
  Maten02,
  Maten03,
  Maten04,
  Maten05,
  Maten06,
  Maten07,
  Maten08,
  Maten09,
  Maten10,
  Maten11,
  LogoGreenSimple,
} from '../assets/img';
import { GApageView } from '../index.js';

const Maten = ({ language }) => {
  const [textResource, setResource] = useState(Text);
  useEffect(() => {
    GApageView('/Maten');
  }, []);
  useEffect(() => {
    language === 'ENG' ? setResource(EngText) : setResource(Text);
  }, [language]);

  const foodImages = [
    Maten03,
    Maten04,
    Maten05,
    Maten06,
    Maten07,
    Maten08,
    Maten09,
    Maten10,
    Maten11,
  ];

  return (
    <div className={styles.food_container}>
      <img src={Maten01} alt='Gamlestova' className={styles.food_hero_image} />
      <div className={styles.food_paragraph}>
        <h1 className={styles.food_title_paragraph}>
          {textResource.FOOD.TITLE}
        </h1>
        <p>{textResource.FOOD.FIRST_PARAGRAPH}</p>
      </div>
      <img src={Maten02} alt='Veranda' className={styles.food_hero_image} />
      <div className={styles.food_paragraph}>
        <h1 className={styles.food_title_paragraph}>
          {textResource.FOOD.SEC_TITLE}
        </h1>
      </div>
      <div className={styles.food_paragraph}>
        {Object.entries(textResource.FOOD.MEALS).map(([key, value]) => {
          return (
            <div key={key} className={styles.food_meal_details}>
              <p className={styles.food_meal_label}>{value.TITLE}</p>
              <p>{value.DESCRIPTION}</p>
            </div>
          );
        })}
      </div>
      <Carousel language={language}>
        {foodImages.map((src, index) => (
          <CarouselItem key={index}>
            <img src={src} alt='dish' className={styles.food_image_carousel} />
          </CarouselItem>
        ))}
      </Carousel>
      <img
        className={styles.food_logo_image}
        src={LogoGreenSimple}
        alt='LogoGreenLeafSimple'
      />
    </div>
  );
};

export default Maten;
