import React, { useEffect, useState } from 'react';
import styles from './Aktivitetar.module.css';
import { AktivitetHero, Aktivitet02 } from '../assets/img/';
import { GApageView } from '../index.js';
import { Text, EngText } from '../assets/TextResources';

const Aktivitetar = ({ language }) => {
  const [textResource, setResource] = useState(Text);

  useEffect(() => {
    GApageView('/Aktivitetar');
  }, []);
  useEffect(() => {
    language === 'ENG' ? setResource(EngText) : setResource(Text);
  }, [language]);

  return (
    <div className={styles.activities_container}>
      <img
        src={AktivitetHero}
        alt='View of the fjord'
        className={styles.activities_hero_image}
      />
      <div className={styles.activities_paragraph}>
        <h1 className={styles.activities_title_paragraph}>
          {textResource.SURROUDINGS.TITLE}
        </h1>
        <div className={styles.activities_paragraph}>
          {Object.entries(textResource.SURROUDINGS.ACTIVITIES).map(
            ([key, value]) => {
              return (
                <div key={key} className={styles.activities_details}>
                  <p className={styles.activities_paragraph_label}>
                    {value.TITLE}
                  </p>
                  <p>{value.DESCRIPTION}</p>
                </div>
              );
            }
          )}
        </div>
      </div>
      <img
        src={Aktivitet02}
        alt='View of the fjord'
        className={styles.activities_hero_image}
      />
    </div>
  );
};

export default Aktivitetar;
