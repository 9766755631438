import React, { useState } from "react";
import styles from "./BurgerMenu.module.css";
import Modal from "@mui/material/Modal";
import RoutesDefinitions from "../../RoutesDefinitions.json";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

const BurgerMenu = ({ language }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  let location = useLocation();
  let menu = [];
  const index = language === "ENG" ? 1 : 0;

  RoutesDefinitions[index].pages.forEach((page) => {
    if (page.isActive) {
      let isCurrentPath = location.pathname === page.path;
      if (!isCurrentPath) {
        isCurrentPath =
          RoutesDefinitions[1].pages.find((p) => p.path === location.pathname)
            ?.pageID === page.pageID;
      }
      menu.push(
        <Link
          key={page.name}
          to={page.path}
          className={`${styles.burger_menu_item} ${
            isCurrentPath && styles.burger_menu_item_selected
          }`}
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          {page.name}
        </Link>
      );
    }
  });

  return (
    <>
      <button
        className={styles.burger_menu_button}
        onClick={() => {
          setMenuOpen(true);
        }}
        onMouseDown={() => {
          setMenuOpen(true);
        }}
      >
        <MenuIcon />
      </button>

      <Modal
        open={isMenuOpen}
        onClose={() => setMenuOpen(false)}
        aria-labelledby="hamburger menu"
        aria-describedby="this menu is for navigating between pages"
        disableEnforceFocus
      >
        <div className={styles.burger_menu_container}>
          <button
            className={styles.close_menu_button}
            onClick={() => setMenuOpen(false)}
            type="button"
          >
            X
          </button>
          {menu}
        </div>
      </Modal>
    </>
  );
};

export default BurgerMenu;
