export const Text = {
  HOME: {
    FIRST_PARAGRAPH:
      "På Skålebotnfjellet kan du puste fritt og late blikket kvile...",
    SEC_PARAGRAPH:
      "På Larsgarden kan du trø i graset og kjenne angen av blømande frukttre",
    THIRD_PARAGRAPH:
      "Larsgarden Tunhotell vart grunnlagt i mai 2020 av kjærleik til garden. Vi ønskjer hjerteleg velkommen til overnatting i Våningshuset frå 1901. I gamlestova og på verandaen tar vi imot gjester for ei unik matoppleving. Larsgarden er eit utstillingsvindauge for matfatet i Sogn.",
  },
  GARDEN: {
    TITLE: "Hagen",
    FIRST_PARAGRAPH:
      "Hagen på Larsgarden består av ein grønsakshage, eit drivhus, fleire ulike typar frukttre og ein hemmeleg hage på nedsida av fjøsen. Mykje av maten me serverer har me sjølv grodd. Gjestene er velkomne til å vere med på innhausting av råvarer. Ta deg gjerne ein tur rundt i hagen for å sjå.",
  },
  SLEEP: {
    SOVE_GODT: {
      TITLE: "Sove godt",
      FIRST_PARAGRAPH:
        "Larsgarden leiger ut heile huset under eitt, og for minst to dagar av gangen. Dette inkluderer inntil 10 sengeplasser fordelt på fire soverom. Alle soveromma ligg på plan to. Stilen er landleg og lun, sengene gode -og utsikta og lyset noko heilt for seg sjølv. Trappa opp til loftet er for dei spreke. Det er eige bad i andre etasje og tilgang til felles bad i første etasje.",
      SEC_TITLE: "Romma våre:",
    },
    ROOMS: {
      OVERSTE_ARKJA: {
        TITLE: "Øvste arkja",
        DESCRIPTION: "Dobbelseng. Utsikt til bøane og Skålebotnfjellet.",
      },
      NESTE_ARKJA: {
        TITLE: "Neste arkja",
        DESCRIPTION:
          "Dobbelseng som kan gjøres om til to enkeltsenger. Utsikt til Høyangsfjorden og Engelsbøfjellet.",
      },
      INSTE_SALEN: {
        TITLE: "Inste salen med alkove",
        DESCRIPTION:
          "Ei dobbelseng (som kan splittast i to enkle) og to enkle senger i alkoven. Høver for fire som kjenner kvarandre godt. Utsikt til grenda og innover Høyangsfjorden.",
      },
      YSTE_SALEN: {
        TITLE: "Yste salen",
        DESCRIPTION:
          "Dobbeltseng som kan gjøres om til to enkeltsenger. Utsikt til Sognefjorden og Stølsheimen",
      },
    },
  },
  FOOD: {
    TITLE: "Maten",
    FIRST_PARAGRAPH:
      "På Larsgarden Tunhotell kan du nyte eit matfat som smakar av grønsaker, bær og frukt frå garden, fisken i fjorden og hjorten i skogen. Maten vert servert i gamlestova i våningshuset frå 1901. Aperitiffen kan nytast i hagen eller på verandaen med den mest storslagne utsikta. Vi ønskjer velkomen til grupper fra minst 10 personer til middagsselskap.",
    SEC_TITLE: "Måltida våre:",
    MEALS: {
      BREAKFAST: {
        TITLE: "Frukost kl. 08-10",
        DESCRIPTION:
          "Frukostbufeten er enkel, men smakfull. Heimelaga brød, saft, syltetøy og frukostblanding rista i sognehonning, står alltid på bordet. Kjøtpålegg og ost frå gardar i Sogn vil du og kunne nyte ei roleg morgonstund.",
      },
      LUNCH: {
        TITLE: "Lunsj og matpakke",
        DESCRIPTION:
          "Vi er stolte over området vi bur i og dei som bur og virkar her. Vi kjem gjerne med gode råd om spisestader i dei ulike bygdene. For dei som ønskjer å vere i ro på garden, kan vi servere lunsj. Gjestene våre kan og bestille matpakke.",
      },
      DINNER: {
        TITLE: "Middag",
        DESCRIPTION:
          "Middagen har alltid vore dagens høgdepunkt på Larsgarden. Vi komponerer dagens meny med utgangspunkt i kva ein kan hauste frå hagen, skogen, fjorden og fjellet. Me lagar vår eigen is og sorbet, i ulike variantar. Aperitiffen vert servert på verandaen eller i hagen. Vi tilbyr middag med 2-3 rettar. Ta kontakt for meir informasjon",
      },
      DRINKS: {
        TITLE: "Drikke",
        DESCRIPTION:
          "Vi serverer juice, cider og øl frå produsentar i Sogn og Sunnfjord, men og vin frå Toscana - som vi har eit nært forhold til. Kaffi frå Sognefjorden Kaffibrenneri gir ein god start på dagen.",
      },
    },
  },
  ABOUT: {
    FIRST_PARAGRAPH:
      "Larsgarden Tunhotell er bygd på kjærleik frå famlilien. Å løyse oppgåver i lag har alltid vore nødvendig på ein bratt vestlandsgard",
    SEC_PARAGRAPH:
      " Janne Elin er 12. generasjon gardbrukar på Berge og har i lag med søster og arkitekt Heidi Kathrin Osland skapt Larsgarden Tunhotell. Med seg på tunet har Janne Elin sønene Lars og Knut. Brødrene har i lag ansvaret for guiding og for merking av turstiar. Far Einvald har restaurert dei gamle bygningane på garden. Mor Gerd har med sine grøne fingrar ei god hand om hagen, og har og sydd tekstilar til stover og soverom. Svoger Svein er byggeleiar, altmuligmann og ølbryggjar. Bror Rune Osland har interesse for historiegransking og kvalitetsikrar der historien vert omtala. Nevøen Simen og sambuar Arisa er teknisk ansvarlege for heimesida.",
  },
  BERGE: {
    TITLE: "Berge ved Høyangsfjorden",
    INGRESS: "Berge ligg:",
    MAP_DESCRIPTION1: "~ 3 timar med båt og bil frå Bergen",
    MAP_DESCRIPTION2: "~ 6,5 timar med bil frå Oslo",
    MAP_DESCRIPTION3: "~ 45 minutt frå Førde Lufthavn",
    PARAGRAPH1:
      "Larsgarden 55/2 ligg ytterst på fjellhylla Berge, høgt over Nordeide der Høyangsfjorden møter Sognefjorden. Vegen frå Nordeide snor seg med krappe svingar opp til Berge. Grenda med dei ti gardane er omkransa av grøderike Skålebotnfjellet som tronar høgt mot himmelen. På andre sida av Høyangsfjorden ligg Engelsbøfjellet som bogar seg ned til Fureneset der fjellet møter Sognefjorden. Frå Larsgarden er det storslagen utsikt og ein kan sjå Stølsheime i det fjerne.",
    PARAGRAPH2:
      "Innerst i Høyangsfjorden ligg industristaden Høyanger som er vaksen fram på krafta frå Bergefjell. Lenger ut ligg den gamle kyrkjestaden Kyrkjebø. I møtet mellom Høyangsfjorden, Sognefjorden og Stølsheimen er veret skiftande og lyset endrar landskapet frå den eine augneblinken til det andre.",
  },
  SURROUDINGS: {
    TITLE: "Aktivitetar",
    ACTIVITIES: {
      STORYTELLING: {
        TITLE: "Historieforteljing i eldhuset",
        DESCRIPTION:
          "Eldhuset vårt er slik det vart oppført for 125 år sidan. Vi er stolt over å vere 12. generasjon på garden og fortel gjerne historier om dei som budde her før oss. Medan det er eld i gruva, får du servert ein aperitiff før middagen eller eit enkelt måltid etter fjellturen med spekemat, ost og lokal cider eller øl.",
      },
      TURER: {
        TITLE: "Turar frå garden",
        DESCRIPTION:
          "Frå Larsgarden kan ein rusle langs vegane i grenda eller gå lengre turar med storslagen utsikt. Ta kontakt med vertskapet for nærare beskrivelse av turane. Matpakke og termos for turen ordnar vi. Når ein kjem ned att, kan ein leske seg med forfriskande drikke frå regionen, på ein benk i hagen eller i ein godstol på verandaen.",
      },
      HARVESTING: {
        TITLE: "Innhausting av mat",
        DESCRIPTION:
          "Som del av førebuinga til måltida vi serverer, kan du vere med å hente inn grønsaker, frukt og bær frå hagen, eller kantarell og bær i skogen. Har du fiskeutstyr, er det og mogleg å fiske i eit av fiskevatna på fjellet.",
      },
      RIVERBATH: {
        TITLE: "Bading i Hølen",
        DESCRIPTION:
          "Hølen ligg omlag 150 m frå tunet og er ein liten badekulp i Saueåna der du kan ta eit forfriskande bad. Under førsteslåtten vart det ofte lange arbeidsdagar med hesjing og kasting av høy inn på høyloftet og i siloen. For ungane var bading i Hølen eit kjærkome avbrekk i sommarvarmen.",
      },
      STOLSTUR: {
        TITLE: "Stølstur til Karthaugane",
        DESCRIPTION:
          "Turen er 3,5 km lang og går gjennom grenda før ein følgjer merka sti opp til stølsområdet Karthaugane. Du kan ete nistepakken på stølshaugen eller inne i selet, som tilhøyrer garden. Du kan bestille guiding når du bestiller opphaldet.",
      },
      RUNDTUR: {
        TITLE: "Rundtur om Skålebotnen",
        DESCRIPTION:
          " Første del av turen er lik som for Stølstur til Karthaugane. Frå Karthaugane går ein vidare opp til Skålebotnfjellet 877 moh. Derifrå kan ein nyte den storslagne utsikta over Sognefjorden og bort til Stølsheimen, før ein går ned att til garden. Turen er omlag 12 km lang. Turen er merka.",
      },
      KRAFTRUTA: {
        TITLE: "Kraftruta over Bergefjell",
        DESCRIPTION:
          "Kraftruta har ei fantastisk utsikt frå start til slutt. Turen startar ved demningen Grimsosen (600 moh) på Høyangerfjellet og går 280 høgdemeter opp til Toppenhytta (878 moh). Vidare vandrar du i småkupert terreng i 3-4 km bort til Skålbotnen (877 moh), og deretter noko bratt ned til Larsgarden (190 moh). Turen er omlag 10,5 km lang og har raud merking fram til Skålebotnen og deretter kvit merking ned til Larsgarden. Du kan bestille guiding når du bestiller opphaldet.",
      },
      ANDRE_TURER: {
        TITLE: "Andre turar i området",
        DESCRIPTION:
          "Gardane på Berge eigde deler av landområda, som la grunnlaget for framveksten av industrien i Høyanger. Larsgarden får årleg straum tilsvarande 1 hestekraft som kompensasjon for framføring av linjenettet. I periodar med bustadmangel i Høyanger, leigde Larsgarden ut husrom til industriarbeidarar og deira familiar. For ein kortare periode, hadde garden landhandel i Bergsvika, der det og var stoggestad for båtruta.",
      },
      BYVANDRING: {
        TITLE: "Byvandring i Høyanger",
        DESCRIPTION:
          "Industristaden Høyanger er grunnlagt på krafta frå Bergefjell. Landet sine mest kjende arkitektar var sterkt invovert både ved utforming av byplan og bygningar. Høyanger industristadmuseum held til i byporten og er verdt eit besøk i seg sjølv. Høyanger kunstlang har etablert ei flott skulptursamling i sentrum.",
      },
      TURAR_FRA_ORTNEVIK: {
        TITLE: "Turar frå Ortnevik til Stølsheimen og Sylvarnes",
        DESCRIPTION:
          "Frå Nordeide kan ein ta ferjeturen på 25 minutt over til Ortnevik. Her byrjar fjellturane Ortnevik-Solrenningen og Ortnevik-Bjørkestølen, begge sørover i Stølsheimen. Ønskjer du ein tur langs fjorden til veglause grender, er turen Ortnevik-Sylvarnes noko heilt for seg sjølv.",
      },
      KULTUR_TURER_BALESTRAND: {
        TITLE: "Kultur og turar i Balestrand",
        DESCRIPTION:
          "Balestrand ligg 45 minutt med bil unna. Her ligg Ciderhuset, den engelske kyrkja og Norsk Reiselivsmuseum. Balestrandfjella med Keipen, Vindreken og Tjugatoten er mellom dei brattaste i området.",
      },
    },
  },
  STONE_BY_STONE: {
    TITLE: "Vi legg stein på stein",
    FIRST_PARAGRAPH:
      "Slik oldefar Lars Larsson Berge med stor flid bygde vegane, steingardane, veitene og bygningane på Larsgarden.",
    SEC_PARAGRAPH:
      "Våningshuset, eldhuset og stølen er restaurert i samsvar med gamle byggetradisjonar, enten det gjeld kva material eller handtverks- teknikkar som er nytta.",
    THIRD_PARAGRAPH:
      "I 2021 bygde vi ny veranda og nytt bad. Vi oppgraderte òg alle soveromma og stovene. Eldhuset på tunet og stølen på Karthaugane restaurerte me ferdig i 2022. I framtida har me ønske om å bygge nokre småhus som skal bli ein del av tunet. Har vi framleis krefter att, meiner vi at steinfjosen kan bli eit fint møterom med stillerom på høyloftet over denne.",
    LAST_PARAGRAPH:
      "Hagen med sine fruktre, bærbusker og grønsaksbedd har alltid vore ein sentral del av gardsdrifta. I 2020 bygde vi først hjortegjerde kring tunet og deretter planta vi 35 frukttre etter modell frå dei gamle frukthagane. I 2021 lagde me ein «hemmeleg hage» med vinranker, rosetre og humler nedanfor sauefjosen.",
  },
  CONTACT: {
    TITLE: "Kontakt oss",
    INFO: "Me tar booking og reservasjon per epost og telefon. Dersom du har  spørsmål er det berre å ta kontakt.",
    CONTACT_NAME: "Janne-Elin Osland:",
    EMAIL: "E-post: ",
    CONTACT_EMAIL: "Janne-elin@larsgardentunhotell.no",
    MOBILE: "Mobil: ",
    CONTACT_PHONE: "+47 957 97 519",
    ORG_NAME: "LarsgardenTunhotell AS",
    ORG_NUMBER: "Org.nr. 924 964 553",
    ADDRESS: "Bergsvegen 134",
    LOCATION: "6995 Kyrkjebø",
  },
  FOOTER: {
    CONTACT: "Kontakt oss",
    INFO: "For meir informasjon og bestilling, ta kontakt med Larsgarden Tunhotell:",
    PHONE: "Telefon: +47 957 97 519",
    MAIL: "E-post: Janne-elin@larsgardentunhotell.no",
    COPYRIGHT: "Ⓒ 2024 Larsgarden Tunhotell",
    FB: "Facebook: ",
    FB_FARM: "@Larsgarden Tunhotell",
    IG: "Instagram: ",
    IG_FARM: "@larsgardentunhotell",
  },
  TO_TOP_BUTTON: {
    BUTTON_TEXT: "Til toppen",
  },
};

export const EngText = {
  HOME: {
    FIRST_PARAGRAPH:
      "A place where you can breathe and let your body and soul rest…",
    SEC_PARAGRAPH:
      "At Larsgarden you can walk (barefoot) in the grass and smell the sweetness of blooming fruit trees",
    THIRD_PARAGRAPH:
      "Larsgarden is a farm with a history going hundreds of years back in time. Out of love for the farm, we created a farm hotel here in May 2020. We are inviting you to experience a stay in the main house, built in 1901. In Gamlestova, the old main sitting room and at our beautiful veranda, we are inviting our guests to a unique gastronomic experience. Our vision is that Larsgarden will be an exhibition of the quality and variety of the cuisine of the Sogn district.",
  },
  GARDEN: {
    TITLE: "Garden",
    FIRST_PARAGRAPH:
      "The garden at Larsgarden Tunhotell includes a vegetable garden, a greenhouse, several types of fruit trees, and a secret garden located below the barn. We grow a lot of the food we serve ourselves. Guests are welcome to participate in harvesting the ingredients. Feel free to take a stroll around the garden to see for yourself.",
  },
  SLEEP: {
    SOVE_GODT: {
      TITLE: "Sleep well",
      FIRST_PARAGRAPH:
        "Larsgarden rents out the whole house as one, and for minimum two days per stay. This includes up to 10 beds spread across four bedrooms. All bedrooms are in 2nd story (1 above ground level). The style is rural and snug, the beds are good, and the scenery and lighting something indescribable. The staircase is for the agile. There is a private bathroom on the second floor and access to a shared bathroom on the first floor.",
      SEC_TITLE: "Our rooms: ",
    },
    ROOMS: {
      OVERSTE_ARKJA: {
        TITLE: "Ovste arkja",
        DESCRIPTION:
          "Queen bed (160 cm x 190 cm). Viewing the fields and Skalebotn mountain.",
      },
      NESTE_ARKJA: {
        TITLE: "Neste arkja",
        DESCRIPTION:
          "King bed (180 cm x 200 cm), can be split to two single 90 cm beds. Viewing the fjord and Engelsbo mountain",
      },
      INSTE_SALEN: {
        TITLE: "Inste salen with Alcove",
        DESCRIPTION:
          "Queen bed (160 cm x 200 cm), can be split to two single 90 cm beds. 2 single beds (80 cm x 200 cm) in the alcove. Suitable for a family or good friends. Viewing the hamlet and the fjord.",
      },
      YSTE_SALEN: {
        TITLE: "Yste salen",
        DESCRIPTION:
          "Queen bed (160 cm x 200 cm), can be split to two single 90 cm beds. Viewing the Sognefjord and the Stolsheimen mountains.",
      },
    },
  },
  FOOD: {
    TITLE: "Food",
    FIRST_PARAGRAPH:
      "At Larsgarden Farm hotel you can enjoy local fresh food. Taste the ecological vegetables, berries and fruits from the farm, fish from the local fjord and deer from the forests surrounding the farm. We serve the food in the Old main living room in the farmhouse from year 1901. The aperitif can be enjoyed in the garden or at the balcony with the most stunning view! We welcome groups of at least 10 people for dinner parties.",
    SEC_TITLE: "Our meals:",
    MEALS: {
      BREAKFAST: {
        TITLE: "Breakfast 8-10 AM",
        DESCRIPTION:
          "Breakfast buffet is simple yet flavorful. Homemade bread, juice, jam, and cereal toasted with local honey from Sogn are always available on the table. You can also enjoy cold cuts and cheese from farms in Sogn for a peaceful morning moment.",
      },
      LUNCH: {
        TITLE: "Lunch and picnic-bags",
        DESCRIPTION:
          "We are proud of our environment and the people that live and works here. We are happy to give you advice about good places to eat in the surrounding villages. If you prefer to stay at our farm, we can serve lunch. It is also possible to book a picnic bag to go.",
      },
      DINNER: {
        TITLE: "Dinner",
        DESCRIPTION:
          "The dinner has always been the highlight of the day at Larsgarden. We compose today's menu based on the ingredients we can harvest from the garden, the forest, the fjord, and the mountains in each season. We make our own ice cream and sorbet, in different varieties. The aperitif is served on the veranda or in the garden.  We offer dinner with 2-3 courses. Contact us for more information.",
      },
      DRINKS: {
        TITLE: "Drinks",
        DESCRIPTION:
          "We serve juices, ciders, and beer from local breweries in Sogn and Sunnfjord, as well as wine from Tuscany – which is very close to our hearts. Coffee from Sognefjorden Coffee-roastery is the best way to start our morning.",
      },
    },
  },
  ABOUT: {
    FIRST_PARAGRAPH:
      "Larsgarden farm hotel is founded with love for the family and the familytraditions. Working together at the farm, solving any obstacle has been a necessity on this steep mountain farm.",
    SEC_PARAGRAPH:
      "Janne Elin is the 12. generation of farmers at Berge, and has, together with her sister and architect Heidi Kathrin Osland founded Larsgarden tunhotell (Larsgarden farm hotel). At the farm you will also find Janne Elin’s sons Lars and Knut. The brothers are responsible for the guiding and marking of the trials at the farm. Heidi and Janne Elin’s father, Einvald, has restored the old buildings at the farm. Mother Gerd has quite a green thumb and cares for the gardens, when she is not sewing curtains and textiles for the sleeping- and living rooms. Brother-in-law Svein is the construction manager, genitor and he brews our own beer. Our brother Rune Osland has quite an interest for the historical aspect of the farm and makes sure that we have updated and correct background materials for our storytelling. The nephew, Simen, and his girlfriend, Arisa, are responsible for the websites and IT.",
  },
  BERGE: {
    TITLE: "Berge by the Hoyangsfjord",
    INGRESS: "Berge is located:",
    MAP_DESCRIPTION1: "• 3 hours by express ferry or car from Bergen",
    MAP_DESCRIPTION2: "• 6,5 hours by car from Oslo",
    MAP_DESCRIPTION3: "• 45 minutes from Forde airport (Bringeland FDE)",
    PARAGRAPH1:
      "Larsgarden 55/2 is located at the far end of the mountain shelf Berge, high above Nordeide where the Hoyangsfjord meet the king of fjords, the Sognefjord. The road from Nordeide winds upwards with sharp curves to the Berge hamlet. The 10 farmsteads at Berge is surrounded by the lucious mountains stretching high up against the sky. At the other side of the Hoyangsfjord you can see Engelbo mountain (The Angels Meadows mountain) bending down to Fureneset meeting the Sognefjord.",
    PARAGRAPH2:
      "From Larsgarden you have a stunning view over the fjords and the far mountains of Stolsheimen. In the end of the Hoyangsfjord you find the industrial site Hoyanger, founded on the hydro power from Berge mountain. Further out the fjord you find the old church site Kyrkjebo. Where the fjords and the mountains meet you find the shimmering, always shifting, lighs changing the view of the landscape from minute to minute.",
  },
  SURROUDINGS: {
    TITLE: "Activities",
    ACTIVITIES: {
      TURER: {
        TITLE: "The surroundings; Walks, hikes, culture and round-trips",
        DESCRIPTION:
          "From Larsgarden you can walk in the hamlet or make longer hikes, all with magnificent views. Ask us for more information about the diffent types of walks and hikes you can make, and we will recommend routes after your liking. If you like, we will make you a picnic bag and a thermos of hot drinks to bring with you. When you return we are ready to serve you a refreshing drink from the region at the garden bench or in a comfortable chair at the balcony!",
      },
      STOLSTUR: {
        TITLE: "Hike to the mountain pasture",
        DESCRIPTION:
          "The hike starts at 200 metres above sea level (MAS). You will follow the old farmroads up to Karthaugane (the Map hills), 550 MAS, which is our mountain pasture. It is possible to borrow the key to the old farmhouse at the pasture and have a rest there. For the bold ones; have a refreshing bath in the river dam by the farm! The hike is apxm 7 kilometres, marked trail.",
      },
      RUNDTUR: {
        TITLE: "Roundtrip hike around Skaalebotnen",
        DESCRIPTION:
          "First part of the hike follows the hike to the mountain pasture Karthaugane, listed above. From Karthaugane you walk further up to Skaalebotn mountain at 877 MAS. Here you can enjoy the stunning view over the Sognefjord and the Stolsheimen mountains before you return to Larsgarden. The hike is 12 kilometres long, marked trail.",
      },
      BYVANDRING: {
        TITLE: "City walk in Hoyanger",
        DESCRIPTION:
          "Hoyanger is an industrial site founded on the hydro power from Berge mountain. The most famous architects in Norway was involved when the city and it’s buildings was designed. Hoyanger industrial museum is situated in the city portal and well worth a visit. You will also find a beautiful sculpture collection in the city centre, established by Hoyanger art association.",
      },
      TURAR_FRA_ORTNEVIK: {
        TITLE: "Hiking in Stolsheimen mountains from Ortnevik",
        DESCRIPTION:
          "Right across the Sognefjord you find the pittoresque village Ortnevik. You can get her by a ferry from Nordeide (25 min). This is a very good starting point for hikes in the beautiful Stolsheimen mountains. Another great trip is a walk along the fjord from Ortnevik to Sylvarnes, a small hamlet without any car roads. This is a remarkable trip with a lot of local cultural history.",
      },
      KULTUR_TURER_BALESTRAND: {
        TITLE: "Balestrand – Culture and walks",
        DESCRIPTION:
          "Balestrand is a 45 minute car ride from Larsgarden. This is one of the oldest tourist destinations in the country. The German Emperor Wilhelm loved this village, and stayed here every summer for many years. It has also been a meeting spot for many famous painters for centuries. You can learn more about this history if you visit The Norwegian museum for travel and tourism in Balestrand. Have a lunch at Ciderhuset, a local cider brewery with a very nice restaurant. Or have dinner at Kvikne hotel, a very popular historic swiss-chalet style hotel with well known cuisine and great view over the fjord. It is possible to do historical walks with guides, rent e-bikes, make a fishing trip with the family, or do hikes to stunning snowcapped peaks around the village. The English church, the statue of the Viking king Bele, and a walk past the historical dragon style houses is also popular cultural activities.",
      },
    },
  },
  STONE_BY_STONE: {
    TITLE: "We lay stone upon stone",
    FIRST_PARAGRAPH:
      "The way our great grandfather Lars Larsson Berge with great effort built the roads, stone fences, ditches, and houses at Larsgarden.",
    SEC_PARAGRAPH:
      "The main house, the old cooking house and the houses at our mountain pasture are all restored according to old building traditions, both in terms of materials and crafting techniques.",
    THIRD_PARAGRAPH:
      "In 2021 we added a new balcony and a new bathroom to the farmhouse. We have refurbished all the bedrooms and living rooms. We also have a vision to add a couple of small houses (suites) which will be a part of the farmstead yard. If we still have the strength and will, we also think that the stone barn will become a beautiful meeting room with a quiet room up at the top floor.",
    LAST_PARAGRAPH:
      "An important part of the farm has always been the garden with its fruit trees, berry bushes and vegetable fields. In 2020 we built a deer-fence around the yard before we planted 35 fruit trees, inspired by the traditional fruit yards. In 2021 we created a “Secret Garden” with vines, roses and hop bushes below the sheep barn.",
  },
  CONTACT: {
    TITLE: "Contact us!",
    INFO: "For booking and reservation, use e-mail or phone. Do not hesitate to contact us if you have any questions.",
    CONTACT_NAME: "Janne-Elin Osland:",
    EMAIL: "Email: ",
    CONTACT_EMAIL: "Janne-elin@larsgardentunhotell.no",
    MOBILE: "Mobile: ",
    CONTACT_PHONE: "+47 957 97 519",
    ORG_NAME: "LarsgardenTunhotell AS",
    ORG_NUMBER: "Org.nr. 924 964 553",
    ADDRESS: "Bergsvegen 134",
    LOCATION: "6995 Kyrkjebø",
  },
  FOOTER: {
    CONTACT: "Contact us",
    INFO: "For booking or more information, contact us directly:",
    PHONE: "Phone: +47 957 97 519",
    MAIL: "Email: Janne-elin@larsgardentunhotell.no",
    COPYRIGHT: "Ⓒ 2023 Larsgarden Tunhotell",
    FB: "Facebook: @Larsgarden Tunhotell",
    IG: "Instagram: @larsgardentunhotell",
  },
  TO_TOP_BUTTON: {
    BUTTON_TEXT: "Back to top",
  },
};
