import React, { useEffect, useState } from 'react';
import styles from './Historia.module.css';
import { Historia01, Historia02, Historia03, Historia04 } from '../assets/img/';
import { GApageView } from '../index.js';
import { Text, EngText } from '../assets/TextResources';

const Historia = ({ language }) => {
  const [textResource, setResource] = useState(Text);

  useEffect(() => {
    GApageView('/Historia');
  }, []);
  useEffect(() => {
    language === 'ENG' ? setResource(EngText) : setResource(Text);
  }, [language]);

  return (
    <div className={styles.stone_container}>
      <img
        src={Historia01}
        alt='Cobblestone fence'
        className={styles.stone_hero_image}
      />
      <div className={styles.stone_paragraph}>
        <h1 className={styles.stone_title}>
          {textResource.STONE_BY_STONE.TITLE}
        </h1>
        <p className={styles.stone_paragraph}>
          <span>{textResource.STONE_BY_STONE.FIRST_PARAGRAPH}</span>
          <span>{textResource.STONE_BY_STONE.SEC_PARAGRAPH}</span>
          <span>{textResource.STONE_BY_STONE.THIRD_PARAGRAPH}</span>
          <span>{textResource.STONE_BY_STONE.LAST_PARAGRAPH}</span>
        </p>
        <div className={styles.stone_images_container}>
          <img
            src={Historia02}
            className={styles.stone_image}
            alt='Janne sitting on scaffold.'
          />
          <img
            src={Historia03}
            className={styles.stone_image}
            alt='Janne planting apple trees.'
          />
          <img
            src={Historia04}
            alt='Janne smiling whie working.'
            className={styles.stone_image}
          />
        </div>
      </div>
    </div>
  );
};

export default Historia;
