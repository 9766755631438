import React, { useEffect, useState } from 'react';
import styles from './Kontakt.module.css';
import Kontakt01 from '../assets/img/photos/kontakt/Kontakt.webp';
import { GApageView } from '../index.js';
import { Text, EngText } from '../assets/TextResources';

const Kontakt = ({ language }) => {
  const [textResource, setResource] = useState(Text);

  useEffect(() => {
    GApageView('/Kontakt');
  }, []);
  useEffect(() => {
    language === 'ENG' ? setResource(EngText) : setResource(Text);
  }, [language]);

  return (
    <div className={styles.contact_container}>
      <div className={styles.contact_content}>
        <h1 className={styles.contact_title}>{textResource.CONTACT.TITLE}</h1>
        <span className={styles.contact_info}>{textResource.CONTACT.INFO}</span>
        <img
          src={Kontakt01}
          alt='Janne-Elin'
          className={styles.contact_person_picture}
        />
        <p className={styles.contact_person}>
          <span className={styles.contact_name}>
            {textResource.CONTACT.CONTACT_NAME}
          </span>
          <span className={`${styles.contact_} ${styles.contact_email}`}>
            {textResource.CONTACT.EMAIL}
            <a
              className={styles.contact_mail_link}
              href='mailto:Janne-elin@larsgardentunhotell.no'
            >
              {textResource.CONTACT.CONTACT_EMAIL}
            </a>
          </span>
          <span className={styles.contact_mobile}>
            {textResource.CONTACT.MOBILE}
            <a className={styles.contact_mobile_number} href='tel:004795797519'>
              {textResource.CONTACT.CONTACT_PHONE}
            </a>
          </span>
        </p>
        <address className={styles.contact_address}>
          <span>{textResource.CONTACT.ORG_NAME}</span>
          <span>{textResource.CONTACT.ORG_NUMBER}</span>
          <span>{textResource.CONTACT.ADDRESS}</span>
          <span>{textResource.CONTACT.LOCATION}</span>
        </address>
      </div>
    </div>
  );
};

export default Kontakt;
